<template>
  <div class="header">
    <router-link to="/">
      <img
        :src="require(`@/assets/svgs/logo.svg`)"
        class="logo"
      >
    </router-link>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LoginHeader',
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 100px;
  background: rgba(230, 240, 255, 0.85);

  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    height: 50px;
  }
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .header {
    background: rgba(230, 240, 255, 0.6);
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
  }
}

@media only screen and (max-width: 770px) {
  .header {
    height: 70px;

    .logo {
      height: 38px;
    }
  }
}
</style>
