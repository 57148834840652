<template>
  <login-layout>
    <template slot="body">
      <div class="sign-up-screen">
        <div class="gradient-bg-section" />
        <div class="sign-up-screen__inner">
          <div class="titles-section">
            <span class="title">
              {{ $t('pages.signUp.title') }}
            </span>
            <span class="subtitle">
              {{ $t('pages.signUp.subtitle') }}
            </span>
          </div>
          <div class="sign-up-screen__main">
            <VerticalTitleInput
              :title="$t('pages.signUp.inputs.email.value')"
              :placeholder="$t('pages.signUp.inputs.email.placeholder')"
              :value="email"
              :valid="this.validEmail"
              :error-label="$t('components.input.invalidEmail')"
              @input="(e) => handleInput('email', e)"
            />
            <VerticalTitleInput
              :title="$t('pages.signUp.inputs.name.value')"
              :placeholder="$t('pages.signUp.inputs.name.placeholder')"
              :value="name"
              :valid="this.validName"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('name', e)"
            />
            <VerticalTitleInput
              type="password"
              :title="$t('pages.signUp.inputs.password.value')"
              :placeholder="$t('pages.signUp.inputs.password.placeholder')"
              :value="password"
              :valid="this.validPassword"
              :error-label="$t('components.input.invalidPassword')"
              @input="(e) => handleInput('password', e)"
            />
            <div class="sign-up-screen__info">
              <span class="password-rules-title">
                {{ $t('pages.signUp.passwordRulesTitle') }}
              </span>
              <ul>
                <li
                  v-for="(rule, index) in $t('pages.signUp.passwordRules')"
                  :key="`password-rule-${index}`"
                >
                  <span
                    class="password-rules"
                    v-html="rule"
                  />
                </li>
              </ul>
              <BaseCheckbox
                :label="$t('pages.signUp.commercialCommunications')"
                :value="commercialCommunications"
                @input="(e) => handleInput('commercialCommunications', e)"
              />
              <BaseCheckbox
                :label="$t('pages.signUp.privacyPolicy')"
                :value="privacyPolicy"
                @input="(e) => handleInput('privacyPolicy', e)"
                :valid="this.privacyPolicy"
                :error-label="$t('components.input.invalidTermsAndConditions')"
              />
            </div>
            <BaseButton
              large
              rectangular
              :label="$t('pages.signUp.createButton')"
              class="sign-up-screen__button"
              @click="handleSignUp"
            />
          </div>
        </div>
      </div>
    </template>
  </login-layout>
</template>
<script>
import {
  VerticalTitleInput,
  BaseButton,
  BaseCheckbox,
} from 'ff-components-lib';
import LoginLayout from '@/components/layout/login/LoginLayout';
import { validateEmail, validatePassword } from '@/utils';

export default {
  name: 'SignUpScreen',
  components: {
    LoginLayout,
    VerticalTitleInput,
    BaseButton,
    BaseCheckbox,
  },
  data() {
    return {
      name: undefined,
      email: undefined,
      password: undefined,
      commercialCommunications: undefined,
      privacyPolicy: undefined,
    };
  },
  computed: {
    validEmail() {
      if (this.email === undefined) {
        return true;
      }
      if (this.email && this.email.length > 0) {
        return validateEmail(this.email);
      }
      return false;
    },
    validName() {
      return (
        this.name === undefined
        || (typeof this.name === 'string' && this.name.length > 0)
      );
    },
    validPassword() {
      if (this.password === undefined) {
        return true;
      }
      if (this.password && this.password.length > 0) {
        return validatePassword(this.password);
      }
      return false;
    },
    validFields() {
      return (
        this.name
        && this.validName
        && this.email
        && this.validEmail
        && this.password
        && this.validPassword
        && this.privacyPolicy
      );
    },
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
    },
    resetFields() {
      this.name = undefined;
      this.email = undefined;
      this.password = undefined;
      this.commercialCommunications = undefined;
      this.privacyPolicy = undefined;
    },
    async handleSignUp() {
      if (!this.validFields) {
        this.name = this.name || '';
        this.email = this.email || '';
        this.password = this.password || '';
        this.privacyPolicy = this.privacyPolicy || false;
      } else {
        try {
          await this.$store.dispatch('signUp', {
            name: this.name,
            email: this.email,
            password: this.password,
            commercialCommunications: this.commercialCommunications,
          });

          this.$router.push({ path: '/' });

          this.resetFields();
        } catch (e) {
          await this.$store.dispatch('displayAlert', {
            text: this.$t('components.alerts.error'),
            type: 'error',
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sign-up-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 196px);

  .gradient-bg-section {
    position: absolute;
    width: 100%;
    height: 80%;
    background: linear-gradient(
      180deg,
      rgba($primary, 0.3) 0%,
      rgba($primary, 0) 100%
    );
    top: 100px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 48px;
    z-index: 10;
    margin-top: 66px;
    margin-bottom: 96px;

    .titles-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 16px;

      .title {
        font-size: 36px;
        font-weight: 600;
        letter-spacing: -0.72px;
      }

      .subtitle {
        color: $grey;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .sign-up-screen__main {
      display: flex;
      flex-direction: column;
      gap: 24px;

      ::v-deep .base-input {
        box-shadow: 0px 4px 10px 0px rgba($primary, 0.1);
      }

      .sign-up-screen__info {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: -16px;

        .password-rules-title {
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          color: $grey;
        }

        .password-rules {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: $grey;
        }

        ul {
          margin-bottom: 0;
          margin-top: -14px;
        }

        li {
          color: $grey;
        }

        ::v-deep .base-checkbox__input {
          width: 20px;
          height: 20px;

          &:after {
            top: 0;
          }
        }

        ::v-deep .base-checkbox__label {
          font-size: 14px;
        }

        a {
          font-size: 14px;
          font-weight: 600;
        }
      }

      .sign-up-screen__button {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: 970px) {
  .sign-up-screen {
    &__inner {
      width: 76%;
      gap: 40px;
      margin-top: 90px;
      margin-bottom: 90px;
    }
  }
}

@media only screen and (max-width: 770px) {
  .sign-up-screen {
    min-height: calc(100vh - 174px);

    .gradient-bg-section {
      top: 70px;
    }

    &__inner {
      width: 84%;
      gap: 40px;
      margin-top: 70px;
      margin-bottom: 70px;

      .titles-section {
        gap: 8px;

        .title {
          font-size: 24px;
          letter-spacing: -0.48px;
        }

        .subtitle {
          font-size: 16px;
        }
      }

      .sign-up-screen__main {
        .sign-up-screen__info {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-top: -16px;
        }

        .sign-up-screen__button {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
