function validateZipCode(e) {
  const re = /^\d{4}-\d{3}$/;
  return re.test(e);
}

function validatePhone(e) {
  const re = /^\d{9}$/;
  return re.test(e);
}

function validateEmail(e) {
  const re = /^.*@.*\..+$/;
  return re.test(e);
}

function validateTime(e) {
  const re = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
  return re.test(e);
}

function validateNumber(e) {
  const re = /^[0-9]+(\.[0-9]+)?$/;
  return re.test(e);
}

function validatePercentage(e) {
  const re = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;
  return re.test(e);
}

function validatePassword(e) {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return re.test(e);
}

export {
  validateZipCode,
  validatePhone,
  validateEmail,
  validateTime,
  validateNumber,
  validatePercentage,
  validatePassword,
};
