function roundNumber(num, dec) {
  return Math.round(num * (10 ** dec)) / (10 ** dec);
}

function removeNull(obj) {
  return (
    Object.entries(obj)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, v]) => v !== null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
  );
}

function numberToDotNotationString(n) {
  const numberString = n.toString();
  const [wholePart, fractionalPart] = numberString.split('.');
  let wholePartString = wholePart
    .split('')
    .reverse()
    .join('')
    .replace(/(.{3})/g, '$1.');
  if (wholePartString[wholePartString.length - 1] === '.') {
    wholePartString = wholePartString.slice(0, -1);
  }
  wholePartString = wholePartString.split('').reverse().join('');
  if (fractionalPart != null) {
    return `${wholePartString},${fractionalPart}`;
  }
  return wholePartString;
}

export {
  roundNumber,
  removeNull,
  numberToDotNotationString,
};
