<template>
  <div class="main-background">
    <LoginHeader />
    <slot name="body" />
    <LoginFooter />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import LoginHeader from '@/components/layout/login/LoginHeader';
import LoginFooter from '@/components/layout/login/LoginFooter';

export default {
  name: 'LoginLayout',
  components: {
    LoginHeader,
    LoginFooter,
  },
  computed: {
    ...mapGetters(['getBreadcrumb']),
  },
  methods: {
    handleBreadcrumbClick(index) {
      this.$router.push({
        path: this.getBreadcrumb[index].route,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-background {
  background-color: $tertiary;
}
</style>
