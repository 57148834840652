<template>
  <div class="login-footer">
    <div
      class="d-flex justify-content-between margin-container"
      v-if="getBreakpoints.mdAndUp"
    >
      <span class="copyright">
        {{ $t('components.subfooter.copyright') }}
      </span>
      <div class="links">
        <router-link to="/privacy-policy">
          {{ $t('components.subfooter.privacyPolicy') }}
        </router-link>
        <router-link to="/cookies-policy">
          {{ $t('components.subfooter.cookiePolicy') }}
        </router-link>
      </div>
    </div>
    <div
      class="login-footer__inner"
      v-else
    >
      <div class="links">
        <router-link to="/privacy-policy">
          {{ $t('components.subfooter.privacyPolicy') }}
        </router-link>
        <router-link to="/cookies-policy">
          {{ $t('components.subfooter.cookiePolicy') }}
        </router-link>
      </div>
      <div class="d-flex flex-column justify-content-end">
        <span class="copyright">
          {{ $t('components.subfooter.copyright') }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LoginFooter',
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
};
</script>
<style lang="scss" scoped>
.login-footer {
  background-color: $secondary;
  height: 96px;
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;

  font-weight: 500;
  font-size: 14px;

  .copyright {
    color: rgba($grey, 0.6);
  }

  .links {
    display: flex;
    align-items: center;
    gap: 60px;
  }

  a {
    color: $grey;
    text-decoration: none;
  }
}

@media only screen and (max-width: 960px) {
  .login-footer {
    height: unset;
    font-size: 12px;

    &__inner {
      display: flex;
      justify-content: space-between;
      margin: 24px;

      .links {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }
    }
  }
}
</style>
